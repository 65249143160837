import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { DefaultHeader } from "components/headers/default-header"

const news = [
  {
    type: "新闻发布",
    released_at: "2022/9/14",
    title:
      "对于那些为获得粉丝而苦恼的Twitter运营负责人来说，这个新页面是一个有用的工具。",
    link: "https://prtimes.jp/main/html/rd/p/000000015.000066114.html",
  },
  {
    type: "新闻发布",
    released_at: "2022/8/24",
    title:
      "自动收集Twitter的口碑：由一群社交网络专家提供的批量查询管理工具。 现在你可以免费使用测试版了!",
    link: "https://prtimes.jp/main/html/rd/p/000000014.000066114.html",
  },
  {
    type: "新闻发布",
    released_at: "2022/7/6",
    title:
      "电子邮件地址抽签 系统免除了填写申请表的需要。 超过5000名运动参与者的记录。",
    link: "https://prtimes.jp/main/html/rd/p/000000013.000066114.html",
  },
  {
    type: "新闻发布",
    released_at: "2022/6/22",
    title:
      "[诊断内容] 诊断活动可以提高用户在Twitter上的产品购买率。 现在你可以免去最初的费用而持有它。",
    link: "https://prtimes.jp/main/html/rd/p/000000012.000066114.html",
  },
  {
    type: "新闻发布",
    released_at: "2022/5/30",
    title:
      "自动获取Twitter和Instagram的帖子。 发布了一项功能，以促进公司网站上产品和服务的吸引力。",
    link: "https://prtimes.jp/main/html/rd/p/000000011.000066114.html",
  },
  {
    type: "新闻发布",
    released_at: "2022/2/28",
    title: "PARKLoT正在为其用于社交网络活动的自动标签采集工具寻找监督员",
    link: "https://prtimes.jp/main/html/rd/p/000000009.000066114.html",
  },
  {
    type: "新闻发布",
    released_at: "2022/1/6",
    title: "刮刮乐活动功能发布! 爆炸性的粉丝增长与激动人心的演示。",
    link: "https://prtimes.jp/main/html/rd/p/000000009.000066114.html",
  },
  {
    type: "新闻发布",
    released_at: "2021/11/11",
    title:
      "电子商务支持活动 让你的Twitter追随者带来销售! 即时赢取活动是完全免费的!",
    link: "https://prtimes.jp/main/html/rd/p/000000008.000066114.html",
  },
  {
    type: "新闻发布",
    released_at: "2021/10/13",
    title:
      "新功能是免费的]Twitter活动工具PARKLoT自动分发亚马逊礼品代码! 发起活动以庆祝其发行。",
    link: "https://prtimes.jp/main/html/rd/p/000000007.000066114.html",
  },
  {
    type: "新闻发布",
    released_at: "2021/9/29",
    title:
      "PARKLoT，SNS活动工具，正在庆祝它的一周年，没有任何初始费用! 10月期间的限时优惠。",
    link: "https://prtimes.jp/main/html/rd/p/000000006.000066114.html",
  },
  {
    type: "新闻发布",
    released_at: "2021/8/4",
    title:
      "推特即时赢取活动工具PARKLoT发布优惠券功能。 无限的优惠券分发，只需每月支付费用!",
    link: "https://prtimes.jp/main/html/rd/p/000000005.000066114.html",
  },
  {
    type: "新闻发布",
    released_at: "2021/1/20",
    title:
      "PARKLoT推出免费功能，自动向转发你帖子的用户发送他们选择的内容的DM! 材料和内容分发变得更容易!",
    link: "https://prtimes.jp/main/html/rd/p/000000004.000066114.html",
  },
  {
    type: "新闻发布",
    released_at: "2020/12/28",
    title:
      "[Twitter Instant Win] PARKLoT实现了 人人都能赢的功能，在传播的同时也刺激了人们的购买行为! 支持行动的活动同时开始!",
    link: "https://prtimes.jp/main/html/rd/p/000000003.000066114.html",
  },
  {
    type: "新闻发布",
    released_at: "2020/11/16",
    title:
      "设计？ 约36%的用户表示，在考虑产品时，他们担心SNS的粉丝数量太少。 PARKLoT举行礼券支持活动，以支持企业的Twitter账户!",
    link: "https://prtimes.jp/main/html/rd/p/000000002.000066114.html",
  },
  {
    type: "新闻发布",
    released_at: "2020/10/12",
    title:
      "[为企业和个人一直提供免费的活动前工具!] 推特即时赢取活动工具PARKLoT正式推出，在2020年10月前创建活动的特别优惠。",
    link: "https://prtimes.jp/main/html/rd/p/000000001.000066114.html",
  },
]

const News = ({ location }) => {
  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo
          pagetitle="新闻"
          pagepath={location.pathname}
          pagedesc="新闻 | PARKLoT提供了一个Twitter活动工具，可以 随心所欲 地运行，只需支付 月费。"
        />
        <main className="information">
          <section className="information__heading py-10 px-4 md:pt-14 md:pb-16 md:px-0">
            <div className="container">
              <h1 className="text-white font-bold">新闻</h1>
            </div>
          </section>

          <section className="information__philosophy bg-white">
            <div className="container py-10 px-4 md:py-14 md:px-0">
              <ul className="news__list">
                {news.map(item => {
                  return (
                    <li className="news__item">
                      <p className="news__title">{item.type} </p>
                      <time className="news__time">{item.released_at}</time>
                      <p className="news__text">
                        <a
                          className="news__link"
                          href={item.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.title}
                        </a>
                      </p>
                    </li>
                  )
                })}
              </ul>
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}

export default News
